'use-scrict'

// import Loadable from 'react-loadable'
// import Loading from './Loading'
import DefaultLayout from '../Containers/containers/DefaultLayout/DefaultLayout'
// import Login from '../Containers/views/Pages/Login/Login'
// import Register from '../Containers/views/Pages/Register/Register'
// import ResendVerifyEmail from '../Containers/views/Pages/Register/ResendVerifyEmail'
// import VerifyEmail from '../Containers/views/Pages/Register/VerifyEmail'
// import ResetPassword from '../Containers/views/Pages/Register/ResetPassword'
// import ForgotPassword from '../Containers/views/Pages/Register/ForgotPassword'
// import BookAge from '../Containers/views/BookAge/BookAge'
// import BookPage from '../Containers/views/Book/BookPage'
// const AsyncNextPage = Loadable({
//   loader: () => import('../Routes/NextPage'),
//   loading: Loading
// })

const routes = [
  // {
  //   title: 'Login',
  //   path: '/login',
  //   component: Login,
  //   exact: true
  // },
  // {
  //   title: 'Register',
  //   path: '/register',
  //   component: Register,
  //   exact: true
  // },
  // {
  //   path: '/verify',
  //   title: 'Verify',
  //   component: VerifyEmail
  // },
  // {
  //   title: 'Resend Verify Email',
  //   path: '/resend_email',
  //   component: ResendVerifyEmail
  // },
  // {
  //   title: 'Reset Password',
  //   path: '/reset_password',
  //   component: ResetPassword
  // },
  // {
  //   title: 'Forgot Password',
  //   path: '/forgot_password',
  //   component: ForgotPassword
  // },
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout
  }
]

export default routes
