import { call, put } from 'redux-saga/effects'
import GiftCodeVerifyActions from '../Redux/GiftCodeVerifyRedux'
import LoginActions from '../Redux/LoginRedux'
export function * giftCodeVerify (api, {params}) {
  try {
    const res = yield call(api, params)
    if (res && res.message === 'Unauthenticated.') {
      yield put(LoginActions.loginFailure())
      return
    }
    if (res.success) {
      yield put(GiftCodeVerifyActions.giftCodeVerifySuccess(res.data))
    } else {
      yield put(GiftCodeVerifyActions.giftCodeVerifyFailure(res.message))
    }
  } catch (error) {
    yield put(GiftCodeVerifyActions.giftCodeVerifyFailure(error.message))
  }
}
