import apisauce from 'apisauce'
import ApiConfig from '../Config/ApiConfig'
const autoBind = require('react-autobind')
class API {
  constructor (loginToken, baseURL = ApiConfig.baseURL) {
    this.api = apisauce.create({
      // base URL is read from the "constructor"
      baseURL,
      // here are some default headers
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
        // 'Authorization': localStorage.getItem('loginToken') ? 'jwt ' + localStorage.getItem('loginToken') : ''
      },
      // 60 second timeout...
      timeout: 60000
    })
    // const naviMonitor = (response) => // console.log('hey!  listen! ', response)
    // this.api.addMonitor(naviMonitor)

    this.loginToken = loginToken

    autoBind(this)
  }

  authenticated (loginToken) {
    // console.log('loginToken', loginToken)
    this.loginToken = loginToken
    // localStorage.setItem('loginToken', loginToken)
    this.api.setHeader('Authorization', 'jwt ' + loginToken)
  }

  handleUnauthorizedRequest (rawResponse) {
    if (rawResponse.status === 401) {
      this.authenticated('')
      return true
    }
    return false
  }

  preprocessResponse (rawResponse) {
    if (rawResponse && rawResponse.problem && rawResponse.problem === 'TIMEOUT_ERROR') {
      return { message: '要求はタイムアウトしました' }
    }

    if (!rawResponse || rawResponse.problem) {
      return { message: '応答なしまたはネットワークエラー' }
    }

    if (this.handleUnauthorizedRequest(rawResponse)) {
      return { message: 'Unauthorized' }
    }

    const result = rawResponse.data || {}
    result.success = result.status === 'success'
    return result
  }

  login (params) {
    return this.api.post('login', params).then(data => {
      const result = data.data
      result.success = result.status === 'success'
      // console.log('result', result)
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  logout () {
    return this.api.get('logout').then(result => {
      this.authenticated('')
      return result
    })
  }

  register (params) {
    return this.api.post('register', params).then(data => {
      const result = data.data
      if (!result) {
        return { message: 'No response' }
      }
      result.success = result.status === 'success'
      if (result.success) {
        this.authenticated(result.data.access_token)
      }
      return result
    })
  }

  resendEmail (params) {
    return this.api.post('resend_email', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  verifyEmail (params) {
    return this.api.get('verify', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  resetPassword (params) {
    return this.api.post('reset_password', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  forgotPassword (params) {
    return this.api.post('forgot_password', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  updateUsername (params) {
    return this.api.post('update_username', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  changePassword (params) {
    return this.api.post('change_password', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  userUpdate (params) {
    return this.api.post('user_update', params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  user (params) {
    return this.api.get('me').then(data => {
      return this.preprocessResponse(data)
    })
  }

  bookList (params) {
    return this.api.get('book_master').then(data => {
      return this.preprocessResponse(data)
    })
  }

  userBook (params) {
    return this.api.get(`/user_book/${params.user_book_id}/${params.variant_id}/${params.resume_code}`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  bookMaster (params) {
    return this.api.get(`/book_master/${params.book_id}`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  bookPreview (params) {
    return this.api.post(`/book_master/preview`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  userBookPreview (params) {
    return this.api.post(`/user_book/preview`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  bookSave (params) {
    return this.api.post(`/user_book/save`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  bookUpdateElements (params) {
    return this.api.post(`/user_book/update_element_master`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  createNewBook (params) {
    return this.api.post(`/user_book/create_new_user_book`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  checkCoupon (params) {
    return this.api.post(`/user_book/check_coupon`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  bookSaveList (params) {
    return this.api.get(`/user_book`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  bookSubmit (params) {
    return this.api.post(`/user_book/submit_purchase`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  genPoseProcess (params) {
    return this.api.get(`/check_gen_pose/${params.id}`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  sendCustomizeUrl (params) {
    return this.api.post(`/send_customize_url`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  giftCodeVerify (params) {
    return this.api.post(`/verify_gift_code`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  saveBook7Elements(params) {
    return this.api.post(`/user_book/save_draff_7`, params).then(data => {
      return this.preprocessResponse(data)
    })
  }

  getBook7Elements(id) {
    return this.api.get(`/user_book/save_draff_7/${id}`).then(data => {
      return this.preprocessResponse(data)
    })
  }
}

const api = new API()

export default api
