import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { Container } from 'reactstrap'

// import {
//   AppFooter,
//   AppHeader
// } from '@coreui/react'
// sidebar nav config
// import navigation from '../../_nav'
// routes config
import routes from '../../routes'
// import DefaultFooter from './DefaultFooter'
// import DefaultHeader from './DefaultHeader'
import PrivateRoute from '../../../Navigation/PrivateRoute'
import { translate } from 'react-i18next'
class DefaultLayout extends Component {
  render () {
    return (
      <div className='app'>
        {/* <AppHeader fixed>
          <DefaultHeader history={this.props.history} />
        </AppHeader> */}
        <div className='app-body'>
          <main className='main'>
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<PrivateRoute key={idx} {...route} />) : (null)
                }
                )}
              </Switch>
            </Container>
          </main>
        </div>
        {/* <AppFooter>
          <DefaultFooter />
        </AppFooter> */}
      </div>
    )
  }
}

export default translate('translations')(DefaultLayout)
