
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  giftCodeVerifyRequest: ['params'],
  giftCodeVerifySuccess: ['data'],
  giftCodeVerifyFailure: ['error'],
  clearData: null
})

export const GiftCodeVerifyTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: [],
  objects: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const giftCodeVerifyRequest = state => state.merge({ fetching: true, error: null })
export const giftCodeVerifySuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })
export const giftCodeVerifyFailure = (state, { error }) => state.merge({ fetching: false, error, data: null})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GIFT_CODE_VERIFY_REQUEST]: giftCodeVerifyRequest,
  [Types.GIFT_CODE_VERIFY_SUCCESS]: giftCodeVerifySuccess,
  [Types.GIFT_CODE_VERIFY_FAILURE]: giftCodeVerifyFailure
})
