import { call, put } from 'redux-saga/effects'
import BookActions from '../Redux/BookRedux'
// import LoginActions from '../Redux/LoginRedux'
export function * bookList (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookListSuccess(res.data))
    } else {
      yield put(BookActions.bookFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookFailure(error.message))
  }
}

export function * bookMaster (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookMasterSuccess(res.data))
    } else {
      yield put(BookActions.userBookFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.userBookFailure(error.message))
  }
}

export function * bookPreview (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookPreviewSuccess(res.data))
    } else {
      yield put(BookActions.bookPreviewFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookPreviewFailure(error.message))
  }
}

export function * bookSave (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookSaveSuccess(res.data))
    } else {
      yield put(BookActions.bookFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookFailure(error.message))
  }
}

export function * bookSaveBook7 (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookSaveSuccess(res.data))
    } else {
      yield put(BookActions.bookFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookFailure(error.message))
  }
}

export function * bookSaveBook7Elements (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookSaveSuccess(res.data))
    } else {
      yield put(BookActions.bookFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookFailure(error.message))
  }
}

export function * createNewBook7 (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookSaveSuccess(res.data))
    } else {
      yield put(BookActions.bookFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookFailure(error.message))
  }
}

export function * bookSaveList (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookSaveListSuccess(res.data))
    } else {
      yield put(BookActions.bookSaveListFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookSaveListFailure(error.message))
  }
}

export function * userBookPreview (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.userBookPreviewSuccess(res.data))
    } else {
      yield put(BookActions.bookPreviewFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookPreviewFailure(error.message))
  }
}

export function * userBook (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.userBookSuccess(res.data))
    } else {
      yield put(BookActions.userBookFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.userBookFailure(error.message))
  }
}

export function * bookSubmit (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.bookSubmitSuccess(res.data))
    } else {
      yield put(BookActions.bookFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.bookFailure(error.message))
  }
}

export function * checkCoupon (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.validatedCouponSuccess(res.data))
    } else {
      yield put(BookActions.validatedCouponFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.validatedCouponFailure(error.message))
  }
}

export function * sendCustomizeUrl (api, { params }) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(BookActions.sendCustomizeUrlSuccess(res.data))
    } else {
      yield put(BookActions.sendCustomizeUrlFailure(res.message))
    }
  } catch (error) {
    yield put(BookActions.sendCustomizeUrlFailure(error.message))
  }
}
