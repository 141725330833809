import React from 'react'
import Loadable from 'react-loadable'
import I18n from '../I18n'

function Loading() {
  return <div></div>
}

// const Login = Loadable({
//   loader: () => import('./views/Pages/Login/Login'),
//   loading: Loading
// })

// const Register = Loadable({
//   loader: () => import('./views/Pages/Register/Register'),
//   loading: Loading
// })

// const VerifyEmail = Loadable({
//   loader: () => import('./views/Pages/Register/VerifyEmail'),
//   loading: Loading
// })

// const ResendVerifyEmail = Loadable({
//   loader: () => import('./views/Pages/Register/ResendVerifyEmail'),
//   loading: Loading
// })

// const ForgotPassword = Loadable({
//   loader: () => import('./views/Pages/Register/ForgotPassword'),
//   loading: Loading
// })

// const ResetPassword = Loadable({
//   loader: () => import('./views/Pages/Register/ResetPassword'),
//   loading: Loading
// })

// const ActiveAccount = Loadable({
//   loader: () => import('./views/Pages/Register/ActiveAccount'),
//   loading: Loading
// })

// const ConfirmReset = Loadable({
//   loader: () => import('./views/Pages/Register/ConfirmReset'),
//   loading: Loading
// })

// const ResetDone = Loadable({
//   loader: () => import('./views/Pages/Register/ResetDone'),
//   loading: Loading
// })

// const Dashboard = Loadable({
//   loader: () => import('./views/Dashboard'),
//   loading: Loading
// })

const BookCustomize = Loadable({
  loader: () => import('./views/BookCustomize/BookCustomize'),
  loading: Loading
})

// const BookPreview = Loadable({
//   loader: () => import('./views/BookPreview/BookPreview'),
//   loading: Loading
// })

// const BookDetail = Loadable({
//   loader: () => import('./views/BookDetail/BookDetail'),
//   loading: Loading
// })

const Manual = Loadable({
  loader: () => import('./views/Manual/Manual'),
  loading: Loading
})

const BookSubmit = Loadable({
  loader: () => import('./views/BookSubmit/BookSubmit'),
  loading: Loading
})

const ConfirmPurchase = Loadable({
  loader: () => import('./views/BookSubmit/ConfirmPurchase'),
  loading: Loading
})

const BookSave = Loadable({
  loader: () => import('./views/BookSave/BookSave'),
  loading: Loading
})

const GiftCodeVerify = Loadable({
  loader: () => import('./views/GiftCodeVerify/GiftCodeVerify'),
  loading: Loading
})

// const Profile = Loadable({
//   loader: () => import('./views/Profile/Profile'),
//   loading: Loading
// })

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  // LOGIN/REGISTER/PASSWORD
  // { path: '/login', name: I18n.t('login'), component: Login },
  // { path: '/register', name: I18n.t('register'), component: Register },
  // { path: '/verify', name: I18n.t('verify'), component: VerifyEmail },
  // { path: '/resend-email', name: I18n.t('resend_verify_email'), component: ResendVerifyEmail },
  // { path: '/reset-password', name: I18n.t('reset_password'), component: ResetPassword },
  // { path: '/forgot-password', name: I18n.t('forgot_password'), component: ForgotPassword },
  // { path: '/active-account/:email', name: I18n.t('active_account'), component: ActiveAccount },
  // { path: '/confirm-reset', name: I18n.t('confirm_reset'), component: ConfirmReset },
  // { path: '/complete-reset', name: I18n.t('complete-reset'), component: ResetDone },
  // WEB
  // { path: '/home', name: I18n.t('dashboard'), component: Dashboard },
  { path: '/manual', name: I18n.t('tab_manual'), component: Manual },
  // { path: '/book/:bookId', name: I18n.t('tab_book_detail'), component: BookDetail },
  // BOOK CUSTOMIZE (REQUIRE AUTH)
  { path: '/book-customize', name: I18n.t('tab_book_customize'), component: BookCustomize },
  // { path: '/book-preview/:bookId', name: I18n.t('tab_book_preview'), component: BookPreview },
  { path: '/book-submit/:bookId', name: I18n.t('tab_book_submit'), component: BookSubmit },
  { path: '/confirm-purchase', name: I18n.t('tab_confirm_purchase'), component: ConfirmPurchase },
  { path: '/book-save', name: I18n.t('tab_book_save'), component: BookSave },
  { path: '/gift-code-verify', name: I18n.t('gift-code-verify'), component: GiftCodeVerify }
  // TEST
  // { path: '/profile', name: I18n.t('tab_profile'), component: Profile }
]

export default routes
