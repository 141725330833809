import { call, put } from 'redux-saga/effects'
import ContactListActions from '../Redux/ContactListRedux'
import LoginActions from '../Redux/LoginRedux'
export function * contactList (api, {params}) {
  try {
    const res = yield call(api, params)
    if (res && res.message === 'Unauthenticated.') {
      yield put(LoginActions.loginFailure())
      return
    }
    if (res.success) {
      yield put(ContactListActions.contactListSuccess(res.data))
    } else {
      yield put(ContactListActions.contactListFailure(res.message))
    }
  } catch (error) {
    yield put(ContactListActions.contactListFailure(error.message))
  }
}
