import { call, put } from 'redux-saga/effects'
import GenPoseProcessActions from '../Redux/GenPoseProcessRedux'
export function * genPoseProcess (api, {params}) {
  try {
    const res = yield call(api, params)
    if (res.success) {
      yield put(GenPoseProcessActions.genPoseProcessSuccess(res.data))
    } else {
      yield put(GenPoseProcessActions.genPoseProcessFailure(res.message))
    }
  } catch (error) {
    yield put(GenPoseProcessActions.genPoseProcessFailure(error.message))
  }
}
