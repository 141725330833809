const ApiConfig = {
  baseURL: process.env.REACT_APP_SERVER_API_URL || 'http://localhost:8000/',
  network: process.env.REACT_APP_NETWORK || 'main',
  resourceURL: process.env.REACT_APP_SERVER_RESOURCE_URL,
  webURL: process.env.REACT_APP_WEB_API_URL,
  shopifyStorefrontToken: process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN,
  shopifyDomain: process.env.REACT_APP_SHOPIFY_DOMAIN,
  envName: process.env.REACT_APP_ENV || 'dev',
  book7VariantId: process.env.REACT_APP_BOOK_7_VARIANT_ID,
}

export default ApiConfig
