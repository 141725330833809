
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  bookListRequest: ['params'],
  bookListSuccess: ['bookListData'],
  bookMasterRequest: ['params'],
  bookMasterSuccess: ['bookMasterData'],
  userBookRequest: ['params'],
  userBookSuccess: ['userBookData'],
  bookPreviewRequest: ['params'],
  bookPreviewSuccess: ['bookPreviewData'],
  userBookPreviewRequest: ['params'],
  userBookPreviewSuccess: ['userBookPreviewData'],
  bookSaveRequest: ['params'],
  bookUpdateBook7Request: ['params'],
  bookUpdateBook7ElementsRequest: ['params'],
  createNewBook7Request: ['params'],
  bookSaveSuccess: ['bookSaveData'],
  bookSubmitRequest: ['params'],
  bookSubmitSuccess: ['bookSubmitData'],
  bookSaveListRequest: ['params'],
  bookSaveListSuccess: ['bookSaveList'],
  validatedCouponRequest: ['params'],
  validatedCouponSuccess: ['validatedCouponData'],
  validatedCouponFailure: ['validatedCouponError'],
  sendCustomizeUrlRequest: ['params'],
  sendCustomizeUrlSuccess: ['sendCustomizeUrlData'],
  sendCustomizeUrlFailure: ['sendCustomizeUrlError'],
  bookFailure: ['error'],
  userBookFailure: ['userBookError'],
  bookPreviewFailure: ['error'],
  clearData: null
})

export const BookTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  bookListData: null,
  bookMasterData: null,
  bookPreviewData: null,
  bookSaveData: null,
  bookSubmitData: null,
  validatedCouponData: null,
  bookSaveList: [],
  objects: {},
  error: null,
  userBookError: null,
  validatedCouponError: null,
  sendCustomizeUrlData: null,
  sendCustomizeUrlError: null,
  sendCustomizeUrlFetching: false,
  fetching: false,
  userBookFetching: false,
  bookPreviewFetching: false,
  validatedCouponFetching: false,
  bookSaveListFetching: false
})

/* ------------- Reducers ------------- */

export const bookListRequest = state => state.merge({ fetching: true, error: null, bookListData: null })
export const bookListSuccess = (state, { bookListData }) => state.merge({ fetching: false, error: null, bookListData })
export const bookMasterRequest = state => state.merge({ userBookFetching: true, error: null, bookMasterData: null })
export const bookMasterSuccess = (state, { bookMasterData }) => state.merge({ userBookFetching: false, error: null, bookMasterData })
export const userBookRequest = state => state.merge({ userBookFetching: true, userBookError: null, userBookData: null })
export const userBookSuccess = (state, { userBookData }) => state.merge({ userBookFetching: false, userBookError: null, userBookData })
export const bookPreviewRequest = state => state.merge({ bookPreviewFetching: true, error: null, bookPreviewData: null })
export const bookPreviewSuccess = (state, { bookPreviewData }) => state.merge({ bookPreviewFetching: false, error: null, bookPreviewData })
export const userBookPreviewRequest = state => state.merge({ bookPreviewFetching: true, error: null, userBookPreviewData: null })
export const userBookPreviewSuccess = (state, { userBookPreviewData }) => state.merge({ bookPreviewFetching: false, error: null, userBookPreviewData })
export const bookSaveRequest = state => state.merge({ fetching: true, error: null, bookSaveData: null })
export const bookUpdateBook7Request = state => state.merge({ fetching: true, error: null, bookSaveData: null })
export const bookUpdateBook7ElementsRequest = state => state.merge({  })
export const createNewBook7Request = state => state.merge({ fetching: true, error: null, bookSaveData: null })
export const bookSaveSuccess = (state, { bookSaveData }) => state.merge({ fetching: false, error: null, bookSaveData })
export const bookSubmitRequest = state => state.merge({ fetching: true, error: null, bookSubmitData: null })
export const bookSubmitSuccess = (state, { bookSubmitData }) => state.merge({ fetching: false, error: null, bookSubmitData })
export const bookSaveListRequest = state => state.merge({ bookSaveListFetching: true, error: null, bookSaveList: [] })
export const bookSaveListSuccess = (state, { bookSaveList }) => state.merge({ bookSaveListFetching: false, error: null, bookSaveList })
export const bookSaveListFailure = (state, { error }) => state.merge({ bookSaveListFetching: false, error, bookSaveList: null })
export const bookFailure = (state, { error }) => state.merge({ fetching: false, error, bookListData: null, bookSaveData: null, bookSaveList: [], bookSubmitData: null })
export const userBookFailure = (state, { userBookError }) => state.merge({ userBookFetching: false, userBookError, userBookData: null, bookMasterData: null })
export const bookPreviewFailure = (state, { error }) => state.merge({ bookPreviewFetching: false, error, userBookPreviewData: null, bookPreviewData: null })
export const validatedCouponRequest = state => state.merge({ validatedCouponFetching: true, validatedCouponError: null, validatedCouponData: null })
export const validatedCouponSuccess = (state, { validatedCouponData }) => state.merge({ validatedCouponFetching: false, validatedCouponError: null, validatedCouponData })
export const validatedCouponFailure = (state, { validatedCouponError }) => state.merge({ validatedCouponFetching: false, validatedCouponError, validatedCouponData: null })
export const sendCustomizeUrlRequest = state => state.merge({ sendCustomizeUrlFetching: true, sendCustomizeUrlError: null, sendCustomizeUrlData: null })
export const sendCustomizeUrlSuccess = (state, { sendCustomizeUrlData }) => state.merge({ sendCustomizeUrlFetching: false, sendCustomizeUrlError: null, sendCustomizeUrlData })
export const sendCustomizeUrlFailure = (state, { sendCustomizeUrlError }) => state.merge({ sendCustomizeUrlFetching: false, sendCustomizeUrlError, sendCustomizeUrlData: null })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BOOK_LIST_REQUEST]: bookListRequest,
  [Types.BOOK_LIST_SUCCESS]: bookListSuccess,
  [Types.BOOK_MASTER_REQUEST]: bookMasterRequest,
  [Types.BOOK_MASTER_SUCCESS]: bookMasterSuccess,
  [Types.USER_BOOK_REQUEST]: userBookRequest,
  [Types.USER_BOOK_SUCCESS]: userBookSuccess,
  [Types.BOOK_PREVIEW_REQUEST]: bookPreviewRequest,
  [Types.BOOK_PREVIEW_SUCCESS]: bookPreviewSuccess,
  [Types.USER_BOOK_PREVIEW_REQUEST]: userBookPreviewRequest,
  [Types.USER_BOOK_PREVIEW_SUCCESS]: userBookPreviewSuccess,
  [Types.BOOK_SAVE_REQUEST]: bookSaveRequest,
  [Types.BOOK_UPDATE_BOOK7_REQUEST]: bookUpdateBook7Request,
  [Types.BOOK_UPDATE_BOOK7_ELEMENTS_REQUEST]: bookUpdateBook7ElementsRequest,
  [Types.CREATE_NEW_BOOK7_REQUEST]: createNewBook7Request,
  [Types.BOOK_SAVE_SUCCESS]: bookSaveSuccess,
  [Types.BOOK_SUBMIT_REQUEST]: bookSubmitRequest,
  [Types.BOOK_SUBMIT_SUCCESS]: bookSubmitSuccess,
  [Types.BOOK_SAVE_LIST_REQUEST]: bookSaveListRequest,
  [Types.BOOK_SAVE_LIST_SUCCESS]: bookSaveListSuccess,
  [Types.VALIDATED_COUPON_REQUEST]: validatedCouponRequest,
  [Types.VALIDATED_COUPON_SUCCESS]: validatedCouponSuccess,
  [Types.VALIDATED_COUPON_FAILURE]: validatedCouponFailure,
  [Types.SEND_CUSTOMIZE_URL_REQUEST]: sendCustomizeUrlRequest,
  [Types.SEND_CUSTOMIZE_URL_SUCCESS]: sendCustomizeUrlSuccess,
  [Types.SEND_CUSTOMIZE_URL_FAILURE]: sendCustomizeUrlFailure,
  [Types.USER_BOOK_FAILURE]: userBookFailure,
  [Types.BOOK_PREVIEW_FAILURE]: bookPreviewFailure,
  [Types.BOOK_FAILURE]: bookFailure
})
