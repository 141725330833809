
import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  genPoseProcessRequest: ['params'],
  genPoseProcessSuccess: ['data'],
  genPoseProcessFailure: ['error'],
  clearData: null
})

export const GenPoseProcessTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: [],
  objects: {},
  error: null,
  fetching: false
})

/* ------------- Reducers ------------- */

export const genPoseProcessRequest = state => state.merge({ fetching: true, error: null })
export const genPoseProcessSuccess = (state, { data }) => state.merge({ fetching: false, error: null, data })
export const genPoseProcessFailure = (state, { error }) => state.merge({ fetching: false, error})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GEN_POSE_PROCESS_REQUEST]: genPoseProcessRequest,
  [Types.GEN_POSE_PROCESS_SUCCESS]: genPoseProcessSuccess,
  [Types.GEN_POSE_PROCESS_FAILURE]: genPoseProcessFailure
})
