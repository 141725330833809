// import { takeLatest, all, takeEvery } from 'redux-saga/effects'
import { takeLatest, all } from 'redux-saga/effects'
import api from '../Services/Api'
/* ------------- Types ------------- */
import { GiftCodeVerifyTypes } from '../Redux/GiftCodeVerifyRedux'
import { GenPoseProcessTypes } from '../Redux/GenPoseProcessRedux'
import { BookTypes } from '../Redux/BookRedux'
import { UploadTypes } from '../Redux/UploadRedux'
import { ContactListTypes } from '../Redux/ContactListRedux'
import { VerifyEmailTypes } from '../Redux/VerifyEmailRedux'
import { EmailContactTypes } from '../Redux/EmailContactRedux'
import { ProfileTypes } from '../Redux/ProfileRedux'
import { ForgotPasswordTypes } from '../Redux/ForgotPasswordRedux'
import { ResetPasswordTypes } from '../Redux/ResetPasswordRedux'
import { ResendVerifyEmailTypes } from '../Redux/ResendVerifyEmailRedux'
import { UserTypes } from '../Redux/UserRedux'
import { StartupTypes } from '../Redux/StartupRedux'
import { LoginTypes } from '../Redux/LoginRedux'
import { RegisterTypes } from '../Redux/RegisterRedux'
/* ------------- Sagas ------------- */
import { giftCodeVerify } from './GiftCodeVerifySaga'
import { genPoseProcess } from './GenPoseProcessSaga'
import { bookList, bookMaster, bookPreview, bookSave, bookSaveList, userBookPreview, userBook, bookSubmit, checkCoupon, sendCustomizeUrl, bookSaveBook7, createNewBook7, bookSaveBook7Elements } from './BookSaga'
import { upload } from './UploadSaga'
import { contactList } from './ContactListSaga'
import { verifyEmail } from './VerifyEmailSaga'
import { emailContact } from './EmailContactSaga'
import { profileUpdateUsername, profileUpdateMainAddress, profileAddAddress, profileChangeWatchword, profileChangePassword, profileDeleteAddress } from './ProfileSaga'
import { forgotPassword } from './ForgotPasswordSaga'
import { resetPassword } from './ResetPasswordSaga'
import { resendVerifyEmail } from './ResendVerifyEmailSaga'
import { user, userUpdate } from './UserSaga'
import { startup } from './StartupSagas'
import { login, logout } from './LoginSagas'
import { register } from './RegisterSaga'
/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup, api),
    // tool generated sagas
    takeLatest(GiftCodeVerifyTypes.GIFT_CODE_VERIFY_REQUEST, giftCodeVerify, api.giftCodeVerify),
    takeLatest(BookTypes.SEND_CUSTOMIZE_URL_REQUEST, sendCustomizeUrl, api.sendCustomizeUrl),
    takeLatest(GenPoseProcessTypes.GEN_POSE_PROCESS_REQUEST, genPoseProcess, api.genPoseProcess),
    takeLatest(BookTypes.VALIDATED_COUPON_REQUEST, checkCoupon, api.checkCoupon),
    takeLatest(BookTypes.BOOK_SUBMIT_REQUEST, bookSubmit, api.bookSubmit),
    takeLatest(BookTypes.USER_BOOK_REQUEST, userBook, api.userBook),
    takeLatest(BookTypes.USER_BOOK_PREVIEW_REQUEST, userBookPreview, api.userBookPreview),
    takeLatest(BookTypes.BOOK_LIST_REQUEST, bookList, api.bookList),
    takeLatest(BookTypes.BOOK_MASTER_REQUEST, bookMaster, api.bookMaster),
    takeLatest(BookTypes.BOOK_PREVIEW_REQUEST, bookPreview, api.bookPreview),
    takeLatest(BookTypes.BOOK_SAVE_REQUEST, bookSave, api.bookSave),
    takeLatest(BookTypes.BOOK_UPDATE_BOOK7_REQUEST, bookSaveBook7, api.bookUpdateElements),
    takeLatest(BookTypes.BOOK_UPDATE_BOOK7_ELEMENTS_REQUEST, bookSaveBook7Elements, api.saveBook7Elements),
    takeLatest(BookTypes.CREATE_NEW_BOOK7_REQUEST, createNewBook7, api.createNewBook),
    takeLatest(BookTypes.BOOK_SAVE_LIST_REQUEST, bookSaveList, api.bookSaveList),
    takeLatest(UploadTypes.UPLOAD_REQUEST, upload, api.upload),
    takeLatest(ContactListTypes.CONTACT_LIST_REQUEST, contactList, api.contactList),
    takeLatest(UserTypes.USER_UPDATE, userUpdate, api.userUpdate),
    takeLatest(UserTypes.USER_REQUEST, user, api.user),
    takeLatest(VerifyEmailTypes.VERIFY_EMAIL_REQUEST, verifyEmail, api.verifyEmail),
    takeLatest(EmailContactTypes.EMAIL_CONTACT_REQUEST, emailContact, api.emailContact),
    // Login
    takeLatest(LoginTypes.LOGIN_REQUEST, login, api.login),
    takeLatest(LoginTypes.LOGOUT_REQUEST, logout, api.logout),

    // Register
    takeLatest(RegisterTypes.REGISTER_REQUEST, register, api.register),

    // Resend Verify Email
    takeLatest(ResendVerifyEmailTypes.RESEND_VERIFY_EMAIL_REQUEST, resendVerifyEmail, api.resendEmail),

    // Reset Password
    takeLatest(ResetPasswordTypes.RESET_PASSWORD_REQUEST, resetPassword, api.resetPassword),

    // Forgot Password
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST, forgotPassword, api.forgotPassword),

    // Profile: update username
    takeLatest(ProfileTypes.PROFILE_UPDATE_USERNAME_REQUEST, profileUpdateUsername, api.updateUsername),
    // Profile: update main address
    takeLatest(ProfileTypes.PROFILE_UPDATE_MAIN_ADDRESS_REQUEST, profileUpdateMainAddress, api.updateMainAddress),
    // Profile: add address
    takeLatest(ProfileTypes.PROFILE_ADD_ADDRESS_REQUEST, profileAddAddress, api.addAddress),
    // Profile: delete address
    takeLatest(ProfileTypes.PROFILE_DELETE_ADDRESS_REQUEST, profileDeleteAddress, api.deleteAddress),
    // Profile: change watchword
    takeLatest(ProfileTypes.PROFILE_CHANGE_WATCHWORD_REQUEST, profileChangeWatchword, api.changeWatchword),
    // Profile: change password
    takeLatest(ProfileTypes.PROFILE_CHANGE_PASSWORD_REQUEST, profileChangePassword, api.changePassword)
  ])
}
